import React, {useEffect, useState, useMemo} from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

export default function VanGoghNav ({frontmatter,fields,children}){
  let {vangogh_object} = frontmatter;

  let backgroundColor = frontmatter.background_color ? frontmatter.background_color : null;
  let backgroundStyle = frontmatter.background_style ? frontmatter.background_style : "cover";
  let backgroundImages = frontmatter.background_images ? frontmatter.background_images.map(e=>e.childImageSharp) : null;
  let images = []
  if(backgroundImages){
    images = images.concat(backgroundImages)
  }
  let [bg,setBg] = useState({})
  // setBg(images[0])

  useEffect((e) => {
    if(images.length){
      console.log("whats good",images)
      setBg(images[Math.floor(Math.random()*images.length)])
    }

    if(typeof window !== "undefined"){

    }
  },[])
  return <StaticQuery
    query={graphql`
      query VanGoghNavObject {
        objects: allFile(filter: {relativeDirectory: {eq: "vangoghpics"}}) {
          edges {
            node {
              id
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `}
    render={ data => {
      let navIcon = frontmatter.vangogh_object ? frontmatter.vangogh_object : "candlestick";
      let object = data.objects.edges.find(e => e.node.name.indexOf(navIcon) > -1)
      return (<div id="vangogh-nav" className={"page-wrapper page-"+(fields.slug ? fields.slug.slice(1,-1): "")}>
        <div className="page-bg">
          {bg.gatsbyImageData && <GatsbyImage object-fit="cover" image={getImage(bg.gatsbyImageData)} alt="" />}
        </div>
        <div id="vangogh-nav-icon" className={"vangogh-nav"+object.node.name}>
        <a href="/vangogh"><GatsbyImage image={getImage(object.node.childImageSharp)} alt={object.node.name}/></a>
        </div>
        {children}
        </div>
      )
    } }
    />
}
